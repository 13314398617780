import { IonItem, IonLabel, IonMenuToggle } from "@ionic/react";

import { useLocation } from "react-router-dom";
import "./Menu.css";

interface Example {
  url: string;
  title: string;
  image: string;
}

let examples: Example[] = [
  {
    title: "Home",
    url: "https://www.henkjurriens.nl/blogs/",
    image: "/assets/dragon.png",
  },
  // {
  //   title: "Examples",
  //   url: "/page/examples",
  // },
  // {
  //   title: "Resources",
  //   url: "/page/resources",
  // },
];

const Menu: React.FC = () => {
  return (
    <>
      <div className="mx-5 lg:mx-32 text-4xl font-extrabold">Examples</div>
      <div className="mx-5 lg:mx-32 p-5 flex">
        {examples.map((example, index) => {
          return (
            <div className="flex-1">
              <div className="bg-gray-300 md:m-20 md:w-80 md:h-80 w-50 h-50 rounded-md">
                <a href={example.url}>
                  <img
                    className="rounded-md"
                    src={example.image}
                    alt="just an example"
                  />
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Menu;

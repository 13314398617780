interface Example {
  url: string;
  title: string;
  intro: string;
  date: string;
  author: string;
}

let blogs: Example[] = [
  {
    title: "React + StoryBook + Tailwindcss + Ionic",
    date: "10-07-2021",
    intro:
      "Since a long time I'm programming in Angular, first AngularJS and later Angular. I was at the Angular conference in Paris where the Angular team announced the removal of controllers...:)...But not long a colleague persuade me to try React. Not totally convinced, but I thought let's give it a try.",
    url: "https://www.henkjurriens.nl/blogs/storybook/",
    author: "Henk Jurriens",
  },
  // {
  //   title: "Examples",
  //   date: "01-01-2021",
  //   intro: "intro",
  //   url: "/page/examples",
  // },
  // {
  //   title: "Resources",
  //   date: "01-01-2021",
  //   intro: "intro",
  //   url: "/page/resources",
  // },
];

const HomeBlog: React.FC = () => {
  return (
    <>
      <div className="mx-5 lg:mx-32 text-4xl font-extrabold">Blog</div>
      <div className="mx-5 lg:mx-32 grid grid-flow-row md:grid-cols-2 gap-4">
        {blogs.map((blog, index) => {
          return (
            <div className="mt-5 ">
              <div className="text-gray-400">{blog.date}</div>
              <div className="text-black text-xl mt-2">{blog.title}</div>
              <div className="mt-2">
                <i>written by {blog.author}</i>
              </div>
              <div className="mt-2">{blog.intro}</div>
              <div className="mt-2 text-purple-500">
                <a href={blog.url}>Read the full story</a>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default HomeBlog;

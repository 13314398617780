import Menu from "./Menu";
import "./Hero.css";

interface ContainerProps {
  name: string;
}

const Hero: React.FC<ContainerProps> = ({ name }) => {
  return (
    <>
      <div className="bg-blue-400 ">
        <div className="flex ">
          <div className="flex-1">
            <Menu></Menu>
            <div className="md:mx-20 mx-5 mt-10 text-left">
              <div className="text-5xl font-black">Ionic Hub</div>
              <div className="mt-10 text-5xl font-black text-white">
                A place for developers by developers
              </div>
              <div className="mt-10 text-white">
                Ionic Framework is an open source mobile UI toolkit for building
                high quality, cross-platform native and web app experiences. It
                will help to move faster with a single code base, to run it
                everywhere with JavaScript and the Web.
              </div>

              <div className="my-5 text-white">
                Ionic Hub will offer coding and UI examples, blogs and other
                resources to get the most out of Ionic Framework!
              </div>

              <div className="my-5 text-white">Cheers @henkjurriens</div>
            </div>
          </div>
          <div className="flex-1  image">
            <img
              src="/assets/logo.svg"
              className="ml-20 w-3/4 text-white"
              alt="logo"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;

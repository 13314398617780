import { IonItem, IonLabel, IonMenuToggle } from "@ionic/react";

import { useLocation } from "react-router-dom";
import "./Menu.css";

interface AppPage {
  url: string;
  iosIcon?: string;
  mdIcon?: string;
  title: string;
}

let appPages: AppPage[] = [
  // {
  //   title: "Home",
  //   url: "/",
  // },
  // {
  //   title: "Examples",
  //   url: "/page/examples",
  // },
  // {
  //   title: "Resources",
  //   url: "/page/resources",
  // },
  // {
  //   title: "Events",
  //   url: "/page/events",
  // },
  // {
  //   title: "Blog",
  //   url: "/page/blog",
  // },
];

const Menu: React.FC = () => {
  const location = useLocation();
  return (
    <div className="flex">
      <span className="flex-1 px-20 pt-5">
        <img
          src="/assets/logo.svg"
          className="w-10 text-extrabold  "
          alt="logo"
        />
      </span>
      <div className="flex-1"></div>

      {appPages.map((appPage, index) => {
        return (
          <div className="flex-1">
            <IonMenuToggle key={index} autoHide={false}>
              <IonItem
                className={location.pathname === appPage.url ? "selected" : ""}
                routerLink={appPage.url}
                routerDirection="none"
                lines="none"
                detail={false}
              >
                <IonLabel className="text-center">
                  <span className="text-white">{appPage.title}</span>
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          </div>
        );
      })}
      <div className="flex-1"></div>
    </div>
  );
};

export default Menu;

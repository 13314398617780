import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useParams } from "react-router";
import Feature from "../components/Feature";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import HomeBlog from "../components/HomeBlog";
import HomeEvents from "../components/HomeEvents";
import HomeExamples from "../components/HomeExamples";
import "./Page.css";

const Home: React.FC = () => {
  const { name } = useParams<{ name: string }>();

  return (
    <IonPage>
      <IonContent fullscreen>
        <Hero name={name} />
        <Feature></Feature>
        <HomeExamples></HomeExamples>
        <HomeBlog></HomeBlog>
        {/* <HomeEvents></HomeEvents> */}
        <Footer></Footer>
      </IonContent>
    </IonPage>
  );
};

export default Home;
